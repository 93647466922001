import React from 'react'
// import { Link } from 'gatsby'
import { LinkAnchor as Link } from '../../components/LinkAnchor'
import {
  Box,
  Container,
  Icon,
  Flex,
  P,
  H1,
  H2,
} from '@allied-solutions/affinity'

import {
  Auto1,
  Building3,
  Ellipsis,
  Add
} from '@allied-solutions/affinity-react-icons/dist/16px'
import { FullScreenLayout, SubmitInsuranceLayout } from '../../components'

const links = [
  {
    icon: Auto1,
    title: 'Vehicle',
    link: '/no-reference-id/lookup/vehicle/',
    extra: '',
  },
  {
    icon: Building3,
    title: 'Home',
    link: '/no-reference-id/lookup/mortgage/',
    extra: '',
  },
  {
    icon: Ellipsis,
    title: 'Other',
    link: '/submit-insurance/other/upload-documents/',
    extra: '(RV, boat, etc)',
  },
  {
    icon: Add,
    title: 'New',
    link: '/submit-insurance/other/upload-documents/',
    extra: '(Loans opened in the last 2 weeks)',
  }
]

const NoRefIdPage = ({ location }) => (
  <SubmitInsuranceLayout location={location}>
    <FullScreenLayout>
      <Container maxWidth="644px" px={8} pt={['3rem', '4rem', null, null]}>
        <Box textAlign="center">
          <H1 className="labelLarge" fontWeight={900} typeStyle="labelLarge">
            Look up my account
          </H1>
          <H2>Select the one that best describes why you’re here.</H2>
        </Box>
        <Flex mt={20} display={[null, null, 'flex']} mb={-4}>
          {links.map(item => (
            <Flex.Col key={`NoRefId__Link--${item.title}`} mt={4}>
              <Link
                id={`NoRefId__Link--${item.title}`}
                to={item.link}
                css={`
                  ${Box} {
                    border: 1px solid ${props => props.theme.colors.default};
                    transition-property: border-color, background-color;
                  }

                  &:hover,
                  &:focus {
                    ${Box} {
                      border-color: ${props => props.theme.colors.blue};
                      background-color: ${props =>
                        props.theme.colors.lightBlue200};
                    }
                  }
                `}
              >
                <Box
                  borderRadius={1}
                  bg="gray20"
                  textAlign="center"
                  height="100%"
                  pt={11}
                  pb={6}
                  maxWidth={56}
                  mx="auto"
                >
                  <Icon
                    src={item.icon}
                    color="blue"
                    display="flex"
                    justifyContent="center"
                  />
                  <P
                    mb={0}
                    as="h3"
                    color="gray1000"
                    typeStyle="bodyLarge"
                    textAlign="center"
                    mt={1}
                  >
                    {item.title}
                  </P>
                  {item.extra && (
                    <P
                      mb={0}
                      as="p"
                      typeStyle="caption"
                      textAlign="center"
                      color="hint"
                    >
                      {item.extra}
                    </P>
                  )}
                </Box>
              </Link>
            </Flex.Col>
          ))}
        </Flex>
      </Container>
    </FullScreenLayout>
  </SubmitInsuranceLayout>
)

export default NoRefIdPage
